import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Typography } from '@material-ui/core'
import { H3, MarkdownHandler } from '@system'

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingBottom: '30px',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      paddingBottom: '80px',
    },
  },

  leftContainer: {
    color: theme.palette.text.tertiary,
    fontSize: theme.typography.body1.fontSize,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
      border: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: '20px',
      borderRight: `1px solid ${theme.palette.secondary.main}`,
    },
  },

  phaseCard: {
    position: 'relative',
    '& h3': {
      marginTop: '0rem',
    },
    padding: '0px 50px',
    borderLeft: '3px solid',
    borderColor: theme.palette.secondary.main,
    '&:last-child': {
      borderLeft: 'none',
    },
    marginLeft: '24px',
  },
  circleLogo: {
    top: '-15px',
    left: '-34px',
    width: '65px',
    height: '65px',
    border: '3px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '50%',
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .gatsby-image-wrapper': {
      height: '60%',
      width: '60%',
    },
  },

  title: {
    color: theme.palette.secondary.main,
  },

  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: 'none',
      paddingBottom: '12px',
    },
    '& a:hover': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '0px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '25%',
      paddingLeft: '40px',
    },
  },
}))

const PhaseCard = (props) => {
  const classes = useStyles()
  return (
    <SbEditable content={props.blok}>
      <Box className={classes.phaseCard}>
        <H3 className={classes.title}>{props.blok.title}</H3>
        <Box className={classes.outerContainer}>
          <Box width="75%" className={classes.leftContainer}>
            {!!props.blok.description && (
              <MarkdownHandler>{props.blok.description}</MarkdownHandler>
            )}
          </Box>
          <Box className={classes.link}>{renderBloks(props.blok.links)}</Box>
        </Box>

        <Box position="absolute" className={classes.circleLogo}>
          {renderBloks(props.blok.icon)}
        </Box>
      </Box>
    </SbEditable>
  )
}

export default PhaseCard
